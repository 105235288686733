import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { JsonDebugger } from "../../components/json-debugger"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  Typography,
} from "antd"
import { Link, navigate } from "gatsby"
import PageContent from "../../components/page-content"
import { StoreContext } from "../../context/store-context"
const { Title } = Typography

const ResetAccountPage: React.FC = (data) => {
  const [title, setTitle] = React.useState("Reset Account")
  const [success, setSuccess] = React.useState(false)
  const { resetPassword, loading } = React.useContext(StoreContext)
  React.useEffect(() => {
    if (!success) {
      return
    }
    setTitle("Account Reset")
  }, [success])
  return (
    <Layout>
      <Seo title={title} />
      <PageContent>
        <Row justify="center">
          <Col md={12}>
            <Card title={<Title>{title}</Title>}>
              {success ? (
                <Result
                  status="success"
                  title="Email sent"
                  subTitle="You should be receiving an email shortly with a link to reset your password."
                  extra={
                    <Button onClick={() => navigate("/")} type="primary">
                      Back Home
                    </Button>
                  }
                />
              ) : (
                <Form
                  name="account_reset"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={({ email }) => {
                    resetPassword({ variables: { email } }).then(({ data }) => {
                      if (!data) {
                        return
                      }
                      const { customerRecover } = data
                      if (
                        Array.isArray(customerRecover.customerUserErrors) &&
                        customerRecover.customerUserErrors.length == 0
                      ) {
                        setSuccess(true)
                      }
                    })
                  }}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </Button>
                      <Link to="/account/login">Cancel</Link>
                    </Space>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </PageContent>
    </Layout>
  )
}

export default ResetAccountPage
